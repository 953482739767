// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, CloudRunPathBuilder, Env, ICandidateEnvironment } from 'shared-lib'

const CANDIDATE_BASE_URL = 'https://demo-network.cnected.com'

const env: Env = 'demo'
const projectNumber = '861440716888'
const projectHash = 'vn645xctzq'
const pathBuilder = new ApiGatewayPathBuilder(env)
const cloudRunPathBuilder = new CloudRunPathBuilder(projectHash)

export const environment: ICandidateEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBRNqXm82BMKEVWhs9uNjMkwnWTCAOcFoE',
    authDomain: 'cnect-demo-100.firebaseapp.com',
    projectId: 'cnect-demo-100',
    storageBucket: 'cnect-demo-100.appspot.com',
    messagingSenderId: projectNumber,
    appId: '1:861440716888:web:6560b03d55cad55cbbda2d',
    measurementId: 'G-CK5E8FJSQM',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-demo.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    registration: pathBuilder.build('registration'),
    events: pathBuilder.build('events'),
    applicationHistory: pathBuilder.build('applicationHistory'),
    content: pathBuilder.build('content'),
    candidate: pathBuilder.build('candidate'),
    employer: pathBuilder.build('employer'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    community: pathBuilder.build('community'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    social: pathBuilder.build('social'),
    notifications: pathBuilder.build('notifications'),
    user: pathBuilder.build('user'),
    sse: cloudRunPathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LdL9f4bAAAAAFVMFNz49EExxbLn-T9KMrDIXg1W',
  tenantId: 'B2C-USER-go3sj',
  cdnCname: 'demo-content.cnected.com',
  basePlatformHost: 'demo-network.cnected.com',
  algoliaAppId: '3YG4F5RXCG',
  version: '503b664',
  employerEmailActionRoute: 'http://demo-employer.cnected.com/#/auth/actions',
  candidateEmailSuccessRoute: 'https://demo-network.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: 'cwdpiIuZAP8dPQqB5s5K',
  betaMessageId: 'FoyYlvOzSxbxbMvU2ykE',
  candidateBaseUrl: CANDIDATE_BASE_URL,
  appDownloadDynamicLink: 'https://cnect.page.link/uEsh',
  tenant: {
    appConfigBaseURL: 'https://demo-content.cnected.com/assets/bootstrap',
    defaultTenant: 'demo-network.cnected.com',
  },
  smartBannerOptions: {
    appIcon: 'https://play-lh.googleusercontent.com/W170UOarQtwNy4GY1JXosU6SD3LBY5OzTYOWsRUL9W6yXYwPoCeUVGeTtuO850ByjSw=w480-h960-rw',
    appStoreUrl: 'https://apps.apple.com/us/app/cnected/id6467040985',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.e11.prod.cnect',
    ttl: `${24 * 3.6e6}`,
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
